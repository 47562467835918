// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import LogoWhite from "@/src/assets/logo-white.svg?react";

export function Header(){
    return(
        <header className="s-header">
            <div className="header-logo">
                <a className="site-logo" href="#">
                    <LogoWhite width={80} />
                </a>
            </div>
            <nav className="header-nav">
                <a href="#0" className="header-nav__close" title="close"><span>Close</span></a>
                <div className="header-nav__content">
                    <h3>Africa Art Fund</h3>
                    <ul className="header-nav__list">
                        <li className="current"><a className="smoothscroll" href="#home" title="home">Home</a></li>
                        <li><a className="smoothscroll" href="#about" title="about">About</a></li>
                        <li><a className="smoothscroll" href="#services" title="services">Services</a></li>
                        <li><a className="smoothscroll" href="#works" title="works">Works</a></li>
                        <li><a className="smoothscroll" href="#contact" title="contact">Contact</a></li>
                    </ul>
                    <p>Perspiciatis hic praesentium nesciunt. Et neque a dolorum <a href="#0">voluptatem</a> porro iusto
                        sequi veritatis libero enim. Iusto id suscipit veritatis neque reprehenderit.</p>
                    <ul className="header-nav__social">
                        <li>
                            <a href="#0"><i className="fab fa-facebook"></i></a>
                        </li>
                        {/*<li>*/}
                        {/*    <a href="#0"><i className="fab fa-twitter"></i></a>*/}
                        {/*</li>*/}
                        <li>
                            <a href="#0"><i className="fab fa-instagram"></i></a>
                        </li>
                    </ul>
                </div>
            </nav>
            {/*<a className="header-menu-toggle" href="#0">*/}
            {/*    <span className="header-menu-icon"></span>*/}
            {/*</a>*/}
        </header>
    )
}