export function AboutSection(){
    return (
        <section id="about" className="s-about">
            <div className="row section-header aos-init aos-animate" data-aos="fade-up">
                <div className="col-full">
                    <h3 className="subhead">Who We Are</h3>
                    <h1 className="display-1">About the Africa Art Fund.</h1>
                </div>
            </div>
            <div className="row aos-init aos-animate" data-aos="fade-up">
                <div className="col-full">
                    <p className="lead">
                        The Africa Art Fund, AFAF, is a newly established Pan-African charity for art and culture that supports the development and sustainability of the African creative environment. The Fund provides grants and support to the continent’s non-profit art and culture sector. Its primary goal is to aggregate financial contributions from individuals and organisations that support the growth of African art and Culture.
                    </p>
                    <p className="lead">
                        Beyond grantmaking activities, the Africa Art Fund will focus on leadership and advocacy
                        initiatives that directly impact non-profit organisations and volunteers in the art and culture
                        sector. It will foster discussions that highlight the significant role of art and culture in
                        Africa's development by creating opportunities for global cultural discourse and nurturing
                        future civic leaders.
                    </p>
                        <p className="lead">
                            For the current fiscal year, the Africa Art Fund will raise and grant a budget exceeding
                            $100 million to support various art and culture projects across the African continent.

                        </p>
                </div>
            </div>
            <div className="row">
                <div className="about-process process block-1-2 block-tab-full">
                    <div className="process__vline-left"></div>
                    <div className="process__vline-right"></div>
                    <div className="col-block process__col aos-init aos-animate" data-item="1" data-aos="fade-up">
                        <div className="process__text">
                            <h4>Our Mission</h4>
                            <p>
                                To support art and culture across the African continent through attracting funds from donors, grantmaking, and providing leadership .
                            </p>
                        </div>
                    </div>
                    <div className="col-block process__col aos-init aos-animate" data-item="2" data-aos="fade-up">
                        <div className="process__text">
                            <h4>Our Vision</h4>
                            <p>
                                Art brings people together, builds communities and promotes economic growth. We believe Art can change and develop the African continent to embrace and achieve her creative potential.
                            </p>
                        </div>
                    </div>
                    <div className="col-block process__col aos-init aos-animate" data-item="3" data-aos="fade-up">
                        <div className="process__text">
                            <h4>Our Values</h4>
                            <p>
                                <li>Art and culture in communities help to develop a cohesive environment</li>
                                    <li>Our art and culture are our heritage, to develop and sustain the future of Africa, we need to preserve our art and culture.</li>
                                        <li>Art and culture drive economic growth</li>

                            </p>
                        </div>
                    </div>
                    <div className="col-block process__col aos-init aos-animate" data-item="4" data-aos="fade-up">
                        <div className="process__text">
                            <h4>Board</h4>
                            <ol>
                            <li>
                                Ifesinachi Comedy Nwanyanwu – President & CEO
                            </li>
                            <li>
                                Okechukwu Iweala – Executive Vice President
                            </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}