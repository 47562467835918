export function ContactSection() {
    return (
        <section id="contact" className="s-contact">
            <div className="row section-header aos-init aos-animate" data-aos="fade-up">
                <div className="col-full">
                    <h3 className="subhead subhead--light">Contact Us</h3>
                    <h1 className="display-1 display-1--light">Get in touch and let's make something great together.</h1>
                </div>
            </div>
            <div className="row">
                <div className="col-full contact-main aos-init aos-animate" data-aos="fade-up">
                    <p>
                        <a href="mailto:official@africaartfund.org" className="contact-email">official@africaartfund.org</a>
                        <span className="contact-number">+234 (803) 452 7318</span>
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col-five tab-full contact-secondary aos-init aos-animate" data-aos="fade-up">
                    <h3 className="subhead subhead--light">Where To Find Us</h3>
                    <p className="contact-address">
                        Hse. 33 Bua Court,<br />
                        15 Dare Salaam street,<br />
                        Wuse 2, Abuja FCT<br />
                    </p>
                </div>
                <div className="col-five tab-full contact-secondary aos-init aos-animate" data-aos="fade-up">
                    <h3 className="subhead subhead--light">Follow Us</h3>
                    <ul className="contact-social">
                        <li>
                            <a href="#0"><i className="fab fa-facebook"></i></a>
                        </li>
                        <li>
                            <a href="#0"><i className="fab fa-instagram"></i></a>
                        </li>
                    </ul>
                    <div className="contact-subscribe">
                        <form id="mc-form" className="group mc-form">
                            <input type="email" value="" name="EMAIL" className="email" id="mc-email" placeholder="Email Address" required />
                            <input type="submit" name="subscribe" value="Subscribe" />
                            <label htmlFor="mc-email" className="subscribe-message"></label>
                        </form>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-full cl-copyright">
                    <span>
                        Copyright © {new Date().getFullYear()} All rights reserved | This template is made with <i className="fa fa-heart" aria-hidden="true"></i> by <a href="https://techmice.africa" target="_blank">Techmice</a>
                    </span>
                </div>
            </div>
            <div className="cl-go-top">
                <a className="smoothscroll" title="Back to Top" href="#top"><i className="icon-arrow-up" aria-hidden="true"></i></a>
            </div>
        </section>
    )
}