import {Header} from "@/src/pages/main-screen/components/header";
import {MainSection} from "./components/main-section";
import {AboutSection} from "./components/about-section";
import {ContactSection} from "./components/contact-section";
import {ProgramsGrantsSection} from "./components/programs-grants-section";

export function MainScreen() {
    return (
        <>
        <Header />
            <MainSection />
            <AboutSection />
            <ProgramsGrantsSection />
            <ContactSection />
        </>
    )
}