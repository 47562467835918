export function ProgramsGrantsSection(){
    return (
        <section id="services" className="s-services light-gray">
            <div className="row section-header aos-init aos-animate" data-aos="fade-up">
                <div className="col-full">
                    <h3 className="subhead">Upcoming Programs</h3>
                </div>
            </div>
            <div className="row services-list block-1-3 block-m-1-2 block-tab-full">
                <div className="col-block service-item aos-init aos-animate" data-aos="fade-up">
                    <div className="service-icon service-icon--brand-identity">
                        <img src={"https://30.nesgroup.org/svgs/NES30%20LOGOS%20SVG.svg"}  alt={"Nigerian Economic Submit"}/>
                    </div>
                    <div className="service-text">
                        <h3 className="h4">NESG</h3>
                        <p>Nigeria Economic Summit Group NESG, national art competition:  <a href={"https://30.nesgroup.org/artcompetition"}>The
                            30th Nigerian Economic Summit | Art Competition (nesgroup.org)</a>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}