import BG from '@/src/assets/bg-home.jpg'
export function MainSection(){
    return (
        <section id="home" className="s-home target-section" data-parallax="scroll" data-image-src={BG}
                 data-natural-width="3000" data-natural-height="2000" data-position-y="top">
            <div style={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                backgroundImage: "url(" + BG + ")",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover"
            }}/>

            <div className="shadow-overlay"/>
            <div className="home-content">
                <div className="row home-content__main">
                    <h1>
                        We are <br/>
                        Africa Art Fund.
                    </h1>
                    <p>
                        Pan-African charity for art and culture that <br/>
                        supports the development and sustainability.
                    </p>
                </div>
            </div>
            <ul className="home-sidelinks">
                <li><a className="smoothscroll" href="#about">About<span>who we are</span></a></li>
                {/*<li><a className="smoothscroll" href="#services">Services<span>what we do</span></a></li>*/}
                <li><a className="smoothscroll" href="#contact">Contact<span>get in touch</span></a></li>
            </ul>
            <ul className="home-social">
                <li className="home-social-title">Follow Us</li>
                <li><a href="#0">
                    <i className="fab fa-facebook"></i>
                    <span className="home-social-text">Facebook</span>
                </a></li>
                {/*<li><a href="#0">*/}
                {/*    <i className="fab fa-twitter"></i>*/}
                {/*    <span className="home-social-text">Twitter</span>*/}
                {/*</a></li>*/}
                <li><a href="#0">
                    <i className="fab fa-linkedin"></i>
                    <span className="home-social-text">LinkedIn</span>
                </a></li>
            </ul>
            <a href="#about" className="home-scroll smoothscroll">
                <span className="home-scroll__text">Scroll Down</span>
                <span className="home-scroll__icon"></span>
            </a>
        </section>
    );
}