import * as React from "react";
const SvgLogoWhite = (props) => /* @__PURE__ */ React.createElement("svg", { width: "100%", height: "100%", viewBox: "0 0 500 500", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlSpace: "preserve", "xmlns:serif": "http://www.serif.com/", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinejoin: "round",
  strokeMiterlimit: 2
}, ...props }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(0.824608,0,0,0.824608,-66.6493,-65.3382)" }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(1,0,0,1,6.6624,-483.585)" }, /* @__PURE__ */ React.createElement("path", { d: "M396.141,675.4C396.141,675.4 227.27,673.54 154.456,699.552L94.728,708.362L100.368,737.743C100.368,737.743 100.375,754.293 133.588,747.557C133.588,747.557 177.118,695.95 584.498,714.201C584.498,714.201 698.038,731.516 646.553,665.006C646.553,665.006 447.391,669.09 396.141,675.4Z", style: {
  fill: "rgb(146,222,178)"
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(1.0467,0,0,1.01026,-7.99801,-444.085)" }, /* @__PURE__ */ React.createElement("path", { d: "M292.315,807.954L292.315,849.885C292.315,855.085 288.24,859.308 283.22,859.308L265.031,859.308C260.011,859.308 255.936,855.085 255.936,849.885L255.936,733.004C255.936,727.803 260.011,723.581 265.031,723.581L367.011,723.581C371.148,723.581 374.507,727.061 374.507,731.348L374.507,746.882C374.507,751.169 371.148,754.649 367.011,754.649L292.315,754.649L292.315,776.886L360.073,776.886C364.211,776.886 367.57,780.367 367.57,784.653L367.57,800.187C367.57,804.474 364.211,807.954 360.073,807.954L292.315,807.954Z", style: {
  fill: "white"
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(1.0467,0,0,1.01026,254.555,-445.107)" }, /* @__PURE__ */ React.createElement("path", { d: "M292.315,807.954L292.315,849.885C292.315,855.085 288.24,859.308 283.22,859.308L265.031,859.308C260.011,859.308 255.936,855.085 255.936,849.885L255.936,733.004C255.936,727.803 260.011,723.581 265.031,723.581L367.011,723.581C371.148,723.581 374.507,727.061 374.507,731.348L374.507,746.882C374.507,751.169 371.148,754.649 367.011,754.649L292.315,754.649L292.315,776.886L360.073,776.886C364.211,776.886 367.57,780.367 367.57,784.653L367.57,800.187C367.57,804.474 364.211,807.954 360.073,807.954L292.315,807.954Z", style: {
  fill: "white"
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(1.04806,0,0,1,-222.896,6.25323)" }, /* @__PURE__ */ React.createElement("path", { d: "M400.237,378.25C398.952,373.365 394.679,369.773 389.613,369.773L386.133,369.773C381.485,369.773 377.506,372.795 375.888,377.065L375.888,408.414C375.888,408.814 375.864,409.208 375.817,409.594C376.061,410.272 376.363,410.919 376.718,411.529L375.381,411.529C374.155,415.17 370.841,417.782 366.95,417.782L349.074,417.782C344.141,417.782 340.136,413.584 340.136,408.414L340.136,317.775C340.136,293.865 358.657,274.454 381.47,274.454L394.522,274.454C417.334,274.454 435.856,293.865 435.856,317.775L435.856,408.449C435.856,413.6 431.866,417.782 426.951,417.782L409.142,417.782C405.257,417.782 401.949,415.168 400.734,411.529L399.028,411.529C399.553,410.625 399.964,409.64 400.238,408.597C400.238,408.548 400.237,408.499 400.237,408.449L400.237,378.25ZM400.878,315.606C400.878,309.232 395.94,304.057 389.858,304.057L386.379,304.057C380.297,304.057 375.359,309.232 375.359,315.606L375.359,339.812C375.359,346.186 380.297,351.361 386.379,351.361L389.858,351.361C395.94,351.361 400.878,346.186 400.878,339.812L400.878,315.606Z", style: {
  fill: "white"
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(1.04806,0,0,1,39.657,5.23052)" }, /* @__PURE__ */ React.createElement("path", { d: "M400.237,378.25C398.952,373.365 394.679,369.773 389.613,369.773L386.133,369.773C381.485,369.773 377.506,372.795 375.888,377.065L375.888,408.414C375.888,408.814 375.864,409.208 375.817,409.594C376.061,410.272 376.363,410.919 376.718,411.529L375.381,411.529C374.155,415.17 370.841,417.782 366.95,417.782L349.074,417.782C344.141,417.782 340.136,413.584 340.136,408.414L340.136,317.775C340.136,293.865 358.657,274.454 381.47,274.454L394.522,274.454C417.334,274.454 435.856,293.865 435.856,317.775L435.856,408.449C435.856,413.6 431.866,417.782 426.951,417.782L409.142,417.782C405.257,417.782 401.949,415.168 400.734,411.529L399.028,411.529C399.553,410.625 399.964,409.64 400.238,408.597C400.238,408.548 400.237,408.499 400.237,408.449L400.237,378.25ZM400.878,315.606C400.878,309.232 395.94,304.057 389.858,304.057L386.379,304.057C380.297,304.057 375.359,309.232 375.359,315.606L375.359,339.812C375.359,346.186 380.297,351.361 386.379,351.361L389.858,351.361C395.94,351.361 400.878,346.186 400.878,339.812L400.878,315.606Z", style: {
  fill: "white"
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(1,0,0,1,-11.042,-490.736)" }, /* @__PURE__ */ React.createElement("path", { d: "M514.858,592.108C514.858,592.108 541.718,564.428 571.446,597.447C571.446,597.447 567.17,633.276 546.454,624.18C546.454,624.18 520.618,627.896 513.556,611.071L514.858,592.108Z", style: {
  fill: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M160.772,603.453C160.772,603.453 184.215,566.214 216.658,603.453C216.658,603.453 215.423,652.235 183.42,641.516C151.417,630.797 162.544,622.554 160.772,603.453Z", style: {
  fill: "white"
} }))));
export default SvgLogoWhite;
