import {MainScreen} from "./pages/main-screen";


function App() {

  return (
        <MainScreen />
  )
}

export default App
